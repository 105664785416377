<template>
	<v-row>
		<v-col cols="12" class="text-center d-flex justify-center align-center flex-column">
			<v-img :src="errorImage" max-width="400" max-height="400" contain v-if="errorImage" />

			<div class="py-16">
				<div style="font-size: 5em" class="my-0 font-weigh-600">404</div>
				<div class="mt-n7 mb-5" style="font-size: 1.4em">{{ errorMessage }}</div>
				<div>
					<v-btn @click="hasHistory ? $router.go(-1) : $router.push('/')" outlined color="button-color">
						<v-icon color="button-color" small left>fa-chevron-left</v-icon>
						geri dön
					</v-btn>
				</div>
			</div>
		</v-col>
	</v-row>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
	ame: '404',
	computed: {
		...mapGetters({
			getSite: 'getSite',
		}),
		hasHistory() {
			return window.history.length > 2;
		},
		error404Settings() {
			return this.getSite.error404Settings;
		},
		errorMessage() {
			if (this.error404Settings === null || this.error404Settings.text === null)
				return 'Aradığınız sayfaya ulaşılamıyor. Maalesef, böyle bir sayfa bulunamadı veya artık var olmayabilir.';

			return this.error404Settings.text;
		},
		errorImage() {
			if (this.error404Settings === null || this.error404Settings.file === null) return null;

			return this.error404Settings.file.fileName;
		},
	},
};
</script>
<style scoped></style>
